import { Fragment, useCallback, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import SmallList from "../../components/data/SmallList";
import { IdentityCardLicense, IdentityCardOperation } from "../../components/entities/IdentityCard";
import Button from "../../components/ui/Button";
import Card from "../../components/ui/Card";
import Header from "../../components/ui/Header";
import ScrollableContent from "../../components/ui/ScrollableContent";
import useForm from "../../hooks/useForm";
import { License, PermissionActive, QuotaCurrent } from "../../models/license";
import { Permissions } from "../../models/user";
import { getRequest, postRequest } from "../../services/request.service";
import { useLazyCurrentUserQuery } from "../../services/slices/api.slice";
import { ActionIcon } from "../../utils/icons";
import './index.scss';

const LicenseForUser = () => {
    const [getMe] = useLazyCurrentUserQuery();
    const navigate = useNavigate();
    const { entity, setChanged, setEntity, hasChanged } = useForm<License>({ active: true });

    const permissions: PermissionActive[] = useMemo(() => Permissions.map(p => ({ _id: p.label, active: !!entity.permissions?.includes(p.key) })), [entity]);
    const quotas: QuotaCurrent[] = useMemo(() => [
        { _id: 'Operations', quota: entity?.operationsQuota ?? 0 },
        { _id: 'Utilisateurs par opération', quota: entity?.usersPerOperationQuota ?? 0 },
    ], [entity]);

    const handleSubmit = useCallback(async (close?: boolean) => {
        postRequest<License>('/license/users', { _id: entity._id, users: entity.users }, {
            successMessage: 'Licence mise à jour avec succès',
            errorMessage: 'Une erreur est survenue lors de l\'enregistrement',
            loader: true
        })
            .then(() => {
                // refresh user and license
                getMe();
                if (close) {
                    navigate(-1)
                } else {
                    setChanged(false);
                }
            })
            .catch(() => null);
    }, [entity]);

    useEffect(() => {
        getRequest<License>('/license/me', { errorMessage: 'Une erreur est survenue lors de la récupération de la license', loader: true })
            .then(setEntity)
            .catch(() => navigate(-1))
    }, []);

    if (!entity) return (null);

    return (
        <Fragment>
            <Header breadcrumbs={[{ label: 'Ma licence' }]} withBorder>
                <Button
                    label="Fermer"
                    color="secondary"
                    onClick={() => navigate(-1)}
                />
                {hasChanged && (
                    <Fragment>
                        <Button
                            label="Enregistrer"
                            icon={ActionIcon.SAVE}
                            onClick={handleSubmit}
                        />
                        <Button
                            label="Enregistrer et fermer"
                            icon={ActionIcon.SAVE_CLOSE}
                            onClick={() => handleSubmit(true)}
                        />
                    </Fragment>
                )}
            </Header>
            <ScrollableContent
                side={
                    <Card title="Permissions">
                        {!!entity.permissions?.length && (
                            <div className="input-column">
                                <label htmlFor="permissions">Permissions</label>
                                <SmallList<PermissionActive>
                                    data={permissions}
                                    columns={[{ key: '_id', label: 'Permission' }, { key: 'active', label: '', mapper: (e) => e.active ? 'Oui' : 'Non' }]}
                                />
                            </div>
                        )}
                        <div className="input-column">
                            <label htmlFor="permissions">Quotas</label>
                            <SmallList<QuotaCurrent>
                                data={quotas}
                                columns={[{ key: '_id', label: 'Permission' }, { key: 'quota', label: 'Limite' }]}
                            />
                        </div>
                    </Card>
                }
            >
                <IdentityCardLicense entity={entity as License} />
                {!!entity.operationsPopulated?.length && (
                    <Card title="Opérations">
                        {entity.operationsPopulated?.map(o => <IdentityCardOperation key={o._id} entity={o} onClick={() => navigate(`/operation/${o._id}`)} />)}
                    </Card>
                )}
            </ScrollableContent>
        </Fragment >
    )
}

export default LicenseForUser;