import { Fragment, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet, redirect, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { MaterialIcon } from '../../models/material';
import { MessageType } from '../../models/message';
import { OperationDto, operationDtoToOperation, OperationIcon, Phase, Phases } from '../../models/operation';
import { Controle } from '../../models/sample';
import { Permission, Role, WorkspaceRole } from '../../models/user';
import useAuth from '../../services/hooks/use-auth.hook';
import useWorkspace from '../../services/hooks/use-workspace';
import { getRequest } from '../../services/request.service';
import { useLazyCurrentUserQuery } from '../../services/slices/api.slice';
import { addToast } from '../../services/slices/ui.slice';
import { clearWorkspace, setOperation, WorkspacePermissions } from '../../services/slices/workspace.slice';
import { ActionIcon } from '../../utils/icons';
import MessageModal from '../MessageModal';
import MenuBar from '../ui/MenuBar';

export const OperationMenuWrapper = () => {
    const location = useLocation();
    const { operation, workspacePermissions } = useWorkspace();
    const { user } = useAuth();
    const [isNotifyModalVisible, setNotifyModalVisible] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleClose = useCallback(() => {
        dispatch(clearWorkspace());
        navigate('/');
    }, []);
    /* 
        <MenuBar.Item
        icon="mdi:arrow-back"
        className="menu-bar-item-icon-black"
        onClick={() => navigate(-1)}
    /> */

    return (
        <Fragment>
            <MenuBar>
                <MenuBar.Item
                    icon="mdi:dots-vertical"
                    label="Menu"
                >
                    {!!workspacePermissions.notify && <MenuBar.SubItem label="Notifier les utilisateurs" icon={ActionIcon.SEND} onClick={() => setNotifyModalVisible(true)} />}
                    {!!workspacePermissions.administrate && <MenuBar.SubItem label="Editer l'opération" icon="mdi:settings-outline" onClick={() => navigate(`/operation/${operation._id}/editer`)} />}
                    <MenuBar.SubItem label="Fermer" icon={ActionIcon.CLOSE} onClick={handleClose} />
                </MenuBar.Item>
                {workspacePermissions.read && (
                    <Fragment>
                        <MenuBar.Item
                            icon={OperationIcon.SYNOPTIQUE}
                            label="Synoptique"
                            onClick={() => navigate(`/operation/${operation._id}`)}
                            active={location.pathname.startsWith(`/operation/${operation._id}/synoptique`) || location.pathname === `/operation/${operation._id}`}
                        />
                        <MenuBar.Item
                            icon="mdi:globe"
                            label="Carte"
                            onClick={() => navigate(`/operation/${operation._id}/carte`)}
                            active={location.pathname.startsWith(`/operation/${operation._id}/carte`)}
                        />
                    </Fragment>
                )}
                {workspacePermissions.write && (
                    <Fragment>
                        <MenuBar.Item
                            icon="mdi:tablet-dashboard"
                            label="Tableau de bord"
                            onClick={() => navigate(`/operation/${operation._id}/tableau-de-bord`)}
                            active={location.pathname.startsWith(`/operation/${operation._id}/tableau-de-bord`)}
                        />
                        <MenuBar.Item
                            icon="mdi:database"
                            label="Saisie des données"
                            active={operation.phases.includes(location.pathname.split('/')[3] as Phase)}
                        >
                            {Phases.filter(p => operation.phases?.includes(p.key)).map(p => (
                                <MenuBar.SubItem
                                    key={p.key}
                                    label={p.label}
                                    onClick={() => navigate(`/operation/${operation._id}/${p.key}`)}
                                    active={location.pathname.startsWith(`/operation/${operation._id}/${p.key}`)}
                                />
                            ))}

                        </MenuBar.Item>
                    </Fragment>
                )}
                {workspacePermissions.administrate && operation.phases.includes(Phase.TRAVAUX) && (
                    <MenuBar.Item
                        icon={MaterialIcon}
                        label="Exigences"
                        onClick={() => navigate(`/operation/${operation._id}/exigences`)}
                        active={location.pathname.startsWith(`/operation/${operation._id}/exigences`)}
                    />
                )}
                {workspacePermissions.read && (
                    <MenuBar.Item
                        icon="mdi:chart-scatter-plot"
                        label="Résultats"
                        active={location.pathname.startsWith(`/operation/${operation._id}/resultats`)}
                    >
                        <MenuBar.SubItem
                            label="Par localisation et phase"
                            onClick={() => navigate(`/operation/${operation._id}/resultats/localisation-et-phase`)}
                            active={location.pathname.startsWith(`/operation/${operation._id}/resultats/localisation-et-phase`)}
                        />
                        {(operation?.phases?.includes(Phase.ETUDE) || operation?.phases?.includes(Phase.PREPARATOIRE)) && (
                            <MenuBar.SubItem
                                label="Mélanges"
                                onClick={() => navigate(`/operation/${operation._id}/resultats/melanges`)}
                                active={location.pathname.startsWith(`/operation/${operation._id}/resultats/melanges`)}
                            />
                        )}
                        {operation.phases.includes(Phase.TRAVAUX) && (
                            <Fragment>
                                <MenuBar.SubItem
                                    label="Matériaux"
                                    onClick={() => navigate(`/operation/${operation._id}/resultats/materiaux`)}
                                    active={location.pathname.startsWith(`/operation/${operation._id}/resultats/materiaux`)}
                                />
                                <MenuBar.SubItem
                                    label="Bilan de travaux"
                                    onClick={() => navigate(`/operation/${operation._id}/resultats/bilan-travaux`)}
                                    active={location.pathname.startsWith(`/operation/${operation._id}/resultats/bilan-travaux`)}
                                />
                            </Fragment>
                        )}
                    </MenuBar.Item>
                )}
                {workspacePermissions.read && (
                    <MenuBar.Item
                        icon="mdi:mailbox-open-outline"
                        label="Boîte aux lettres"
                        onClick={() => navigate(`/operation/${operation._id}/boite-aux-lettres`)}
                        active={location.pathname.startsWith(`/operation/${operation._id}/boite-aux-lettres`)}
                    />
                )}
            </MenuBar>
            <Outlet />
            {isNotifyModalVisible && <MessageModal type={MessageType.OPERATION_UPDATE} message={`${operation.name} : synoptique mis à jour`} onClose={() => setNotifyModalVisible(false)} onSubmit={() => setNotifyModalVisible(false)} />}
        </Fragment>
    )
}

const OperationWrapper = () => {
    const { operationId } = useParams();
    const { isOperationLoaded, operation } = useWorkspace();
    const { user, permissions } = useAuth();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [getUser] = useLazyCurrentUserQuery();

    const getOperation = useCallback(async (_id: string) => {
        getRequest<OperationDto>(`/operation/${_id}`, { loader: true })
            .then((dto) => {
                const operation = operationDtoToOperation(dto);
                let operationPermissions: Partial<WorkspacePermissions> = {};

                if (user.role === Role.SUPER_ADMIN) {
                    operationPermissions = {
                        administrate: true,
                        notify: true,
                        upload: true,
                        validate: true,
                        write: true,
                        read: true,
                        localizedResults: true,
                        materialResults: true,
                        synoptiqueData: true,
                        filter: true,
                        controles: [Controle.EXTERIEUR, Controle.EXTERNE, Controle.AUTRE]
                    };
                } else {
                    let operationRole = dto.users.find(ur => ur.user === user._id)?.role;

                    if (dto.licensePopulated?.manager === user._id) {
                        operationRole = WorkspaceRole.ADMIN;
                    }

                    operationPermissions = {
                        administrate: operationRole === WorkspaceRole.ADMIN,
                        notify: operationRole === WorkspaceRole.ADMIN,
                        upload: operationRole === WorkspaceRole.ADMIN,
                        validate: operationRole === WorkspaceRole.ADMIN || operationRole === WorkspaceRole.VALIDATE,
                        write: !!operationRole && operationRole !== WorkspaceRole.READ,
                        read: !!operationRole && [WorkspaceRole.ADMIN, WorkspaceRole.VALIDATE, WorkspaceRole.READ, WorkspaceRole.WRITE].includes(operationRole),
                        localizedResults: !!dto.licensePopulated ? !!dto.licensePopulated.permissions.includes(Permission.LOCALIZED_RESULTS) : permissions.localizedResults,
                        materialResults: !!dto.licensePopulated ? !!dto.licensePopulated.permissions.includes(Permission.MATERIAL_RESULTS) : permissions.materialResults,
                        synoptiqueData: !!dto.licensePopulated ? !!dto.licensePopulated.permissions.includes(Permission.SYNOPTIQUE_DATA) : permissions.synoptiqueData,
                        filter: !!dto.licensePopulated ? !!dto.licensePopulated.permissions.includes(Permission.FILTER) : permissions.filter,
                        controles: !!operationRole && [WorkspaceRole.ADMIN, WorkspaceRole.VALIDATE, WorkspaceRole.WRITE].includes(operationRole)
                            ? [Controle.EXTERIEUR, Controle.EXTERNE, Controle.AUTRE]
                            : [
                                ...(operationRole === WorkspaceRole.EXTERIEUR ? [Controle.EXTERIEUR] : []),
                                ...(operationRole === WorkspaceRole.EXTERNE ? [Controle.EXTERNE] : []),
                                ...(operationRole === WorkspaceRole.AUTRE ? [Controle.AUTRE] : []),
                            ]
                    };
                }

                dispatch(setOperation({ operation, permissions: operationPermissions }));
                setSearchParams({});
            })
            .catch((e) => {
                dispatch(clearWorkspace());
                if ((e as any)?.error?.status === 401) {
                    navigate('/erreur/401');
                } else {
                    dispatch(addToast({
                        type: 'error', message: 'Une erreur est survenue. Veuillez contacter l\'administrateur.', error: e as any
                    }));
                    navigate('/');
                }
            });
    }, [user, permissions]);

    useEffect(() => {
        if (operationId && (!operation?._id || operation._id !== operationId || !!searchParams.get('refresh_operation'))) {
            getOperation(operationId);

            // Refresh user to refresh operationcount on license
            if (!!searchParams.get('refresh_operation') && user.managedLicense) {
                try {
                    getUser()
                } catch { }
            }
        } else if (!operationId) {
            redirect('/');
        }
    }, [operationId, searchParams]);

    if (!operationId || !isOperationLoaded || (operation._id !== operationId) || !!searchParams.get('refresh_operation')) return null;

    return <Outlet />
}

export default OperationWrapper;

