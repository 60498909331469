import { useState } from 'react';
import ScrollableContent from '../../../components/ui/ScrollableContent';
import Toolbar from '../../../components/ui/Toolbar';
import { Diagnostic, DiagnosticElement } from '../../../models/diagnostic';
import { MarkerIcon } from '../../../models/marker';
import { OuvrageElement } from '../../../models/ouvrage';
import { OuvrageMarker } from '../../../models/ouvrage-marker';
import useWorkspace from '../../../services/hooks/use-workspace';
import { getRequest } from '../../../services/request.service';
import OuvrageMarkerForm from '../OuvrageMarkerForm';
import Visualization from '../Visualization';
import VisualizationMarker from '../Visualization/components/VisualizationMarker';
import VisualizationViewButtons from '../Visualization/components/VisualizationViewButtons';
import { DiagnosticElementForm } from './DiagnosticElements';
import './index.scss';

interface DiagnosticViewProps {
    diagnostic: Partial<Diagnostic>;
    onElementChange: (element: DiagnosticElement) => void;
}

const DiagnosticView = ({ diagnostic, onElementChange }: DiagnosticViewProps) => {
    const { ouvrage } = useWorkspace();
    const [markerToEdit, setMarkerToEdit] = useState<Partial<OuvrageMarker>>();
    const [markers, setMarkers] = useState<OuvrageMarker[]>([]);
    const [view, setView] = useState<"front" | "top" | "left" | "right" | "back" | "bottom">('front');
    const [selectedElement, setSelectedElement] = useState<OuvrageElement>();

    const handleSubmitElement = (element: DiagnosticElement) => {
        onElementChange(element);
        setSelectedElement(undefined);
    }

    const getMarkers = () => diagnostic._id ? getRequest<OuvrageMarker[]>('/ouvrage-marker', { params: { ouvrage: ouvrage._id, diagnostic: diagnostic._id }, loader: true })
        .then(setMarkers)
        .catch(() => null) : null;

    return (
        <ScrollableContent id="diagnostic-view" noScroll noPadding>
            <Toolbar>
                <Toolbar.ToolbarBlock label="Point d'intérêt">
                    <Toolbar.ToolbarButton
                        label="Nouveau point"
                        icon={MarkerIcon}
                        onClick={() => setMarkerToEdit({ ouvrage: ouvrage._id, element: selectedElement?._id, diagnostic: diagnostic._id })}
                    />
                </Toolbar.ToolbarBlock>
                <Toolbar.ToolbarBlock label="Vue">
                    <VisualizationViewButtons view={view} setView={setView} />
                </Toolbar.ToolbarBlock>
            </Toolbar>
            <div id="diagnostic-view-view">
                <Visualization
                    ouvrage={ouvrage}
                    onMarker={markerToEdit && !markerToEdit?.coordinates ? (coordinates) => setMarkerToEdit({ ...markerToEdit, coordinates }) : undefined}
                    selected={selectedElement}
                    onSelect={!markerToEdit ? setSelectedElement : undefined}
                    view={view}
                >
                    {markers.map(marker => (
                        <VisualizationMarker
                            key={marker._id}
                            selected={markerToEdit?._id === marker._id}
                            marker={marker}
                            onClick={!markerToEdit ? () => setMarkerToEdit(marker) : undefined}
                            hide={marker._id === markerToEdit?._id}
                            ghost={!!selectedElement && marker.element !== selectedElement._id}
                        />
                    ))}
                    {!!markerToEdit?.coordinates && <VisualizationMarker marker={markerToEdit} onMove={(coordinates) => setMarkerToEdit({ ...markerToEdit, coordinates })} />}
                </Visualization>
                {!!markerToEdit?.coordinates && (
                    <OuvrageMarkerForm
                        onDelete={getMarkers}
                        marker={markerToEdit}
                        onClose={() => setMarkerToEdit(undefined)}
                        onSubmit={getMarkers}
                    />
                )}
                {!!selectedElement && !markerToEdit && (
                    <DiagnosticElementForm
                        element={selectedElement}
                        diagnosticElement={diagnostic.elements?.find(e => e.elementId === selectedElement._id)}
                        onClose={() => setSelectedElement(undefined)}
                        onSubmit={handleSubmitElement}
                    />
                )}
            </div>
        </ScrollableContent>
    )
}

export default DiagnosticView;