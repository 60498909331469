import { Icon } from "@iconify/react";
import { ReactNode } from "react";
import { AnalysisIcon } from "../../../models/analysis";
import { BusinessIcon } from "../../../models/business";
import { License, LicenseIcon } from "../../../models/license";
import { CoucheTypes, Lot, LotIcon } from '../../../models/lot';
import { Marker, MarkerIcon } from '../../../models/marker';
import { Material, MATERIAL_IDENTITY_FIELDS, MaterialIcon, MaterialTypes } from "../../../models/material";
import { Melange, MelangeIcon } from "../../../models/melange";
import { Operation, OperationSubType, OperationSubTypes, Phases, RoadPositionLabel } from '../../../models/operation';
import { Ouvrage, OuvrageGrades, OuvrageIcon, OuvrageTypes } from "../../../models/ouvrage";
import { Population, PopulationIcon, PopulationTypeLabel, PopulationTypes } from '../../../models/population';
import { Controles, Sample, SampleIcon } from '../../../models/sample';
import { Roles, User, UserIcon } from "../../../models/user";
import useWorkspace from "../../../services/hooks/use-workspace";
import { formatDate, formatNumberToFixedDecimal, getFullName } from '../../../utils/format';
import { getNestedField, isType } from "../../../utils/objects";
import { floatToPrString } from '../../../utils/pr';
import Tag from "../../ui/Tag";
import './index.scss';

interface IdentityCardProps<T> {
    entity: T;
    onClick?: (e: T) => void,
    selected?: boolean,
    menu?: ReactNode,
    actions?: ReactNode
}

const IdentityCard = ({ entity }: { entity: any }) => {
    if (isType<Lot>(entity, (e) => e.hasOwnProperty('position'))) {
        return <IdentityCardLot entity={entity} />
    } else if (isType<Sample>(entity, (e) => e.hasOwnProperty('layers'))) {
        return <IdentityCardSample entity={entity} />
    } else if (isType<Population>(entity, (e) => e.hasOwnProperty('problematicDataCount'))) {
        return <IdentityCardPopulation entity={entity} />
    } else if (isType<Operation>(entity, (e) => e.hasOwnProperty('symoptique'))) {
        return <IdentityCardOperation entity={entity} />
    } else if (isType<Marker>(entity, (e) => e.hasOwnProperty('location') && !e.hasOwnProperty('type'))) {
        return <IdentityCardMarker entity={entity} />
    } else if (isType<Melange>(entity, (e) => e.hasOwnProperty('samples'))) {
        return <IdentityCardMelange entity={entity} />
    } else {
        return (null);
    }
}

export const IdentityCardLot = ({
    entity,
    onClick
}: IdentityCardProps<Lot>) => {
    const { operation } = useWorkspace();
    return (
        <div className="identity-card" onClick={() => typeof onClick === 'function' ? onClick(entity) : undefined}>
            <div className="identity-card-header">
                <div className="identity-card-name">
                    <Icon icon={LotIcon.LAYER} />
                    <span>{entity.fullLot}</span>
                </div>
                <div className="identity-card-tags">
                    <Tag value={entity.type} items={CoucheTypes} />
                </div>
            </div>
            <div className="identity-card-details">
                <div>
                    <Icon icon="mdi:calendar" className="icon-small" />
                    <span>{formatDate(entity.date)}</span>
                </div>
                <div>
                    <Icon icon={MaterialIcon} className="icon-small" />
                    <div>{entity.materialPopulated?.name ?? ''}</div>
                </div>
                <div>
                    <Icon icon="mdi:location" className="icon-small" />
                    <div>
                        {!!entity.way && <span>Sens {entity.way}</span>}
                        {!!entity.zone?.prStart && !!entity.zone?.prEnd && <span>{floatToPrString(entity.zone.prStart)} à {floatToPrString(entity.zone.prEnd)}</span>}
                    </div>
                </div>
                <div>
                    <Icon icon="mdi:road-variant" className="icon-small" />
                    <div>
                        {entity.roads.map(r => operation.roadsObj[r]?.realName).join(', ')}
                    </div>
                </div>
                <div>
                    <Icon icon="mdi:arrow-collapse-vertical" className="icon-small" />
                    <div>{entity.thickness.toFixed(2) + 'cm'}</div>
                </div>
            </div>
        </div>
    );
}

export const IdentityCardMelange = ({
    entity,
    onClick
}: IdentityCardProps<Melange>) => {
    return (
        <div className={`identity-card ${entity?.controle ?? ''}`}>
            <div className="identity-card-header">
                <div className="identity-card-name">
                    <Icon icon={MelangeIcon} />
                    {entity.problematic && <Icon icon="fluent:warning-16-regular" className="color-error" />}
                    <span>{entity.name}</span>
                </div>
                <div className="identity-card-tags">
                    <Tag value={entity.controle} items={Controles} />
                    <Tag value={entity.phase} items={Phases} />
                </div>
            </div>
            <div className="identity-card-details">
                <div>
                    <Icon icon="mdi:calendar" className="icon-small" />
                    <span>{formatDate(entity.date)}</span>
                </div>
                <div>
                    <Icon icon="mdi:eyedropper" className="icon-small" />
                    <div>
                        Carottes : {entity.samples.length}
                    </div>
                </div>
            </div>
        </div>
    );
}

export const IdentityCardSample = ({
    entity,
    onClick
}: IdentityCardProps<Sample>) => {
    const { operation } = useWorkspace();
    return (
        <div className={`identity-card ${entity?.controle ?? ''}`}>
            <div className="identity-card-header">
                <div className="identity-card-name">
                    <Icon icon={SampleIcon[entity.type]} />
                    {entity.problematic && <Icon icon="fluent:warning-16-regular" className="color-error" />}
                    <span>{entity.name}</span>
                </div>
                <div className="identity-card-tags">
                    <Tag value={entity.controle} items={Controles} />
                    <Tag value={entity.phase} items={Phases} />
                </div>
            </div>
            <div className="identity-card-details">
                <div>
                    <Icon icon="mdi:calendar" className="icon-small" />
                    <span>{formatDate(entity.date)}</span>
                </div>
                <div>
                    <Icon icon="mdi:location" className="icon-small" />
                    <div>
                        {operation.synoptique === 1 && !!entity.location?.pr && <span>{floatToPrString(entity.location?.pr) ?? ''}</span>}
                        {!!entity.location?.roadPosition && <span>{entity.location?.roadPosition ? RoadPositionLabel[entity.location?.roadPosition] : ''}</span>}
                        {!!entity.location?.location && <span>{entity.location?.location}</span>}
                    </div>
                </div>
                {!!entity.location.road && (
                    <div>
                        <Icon icon="mdi:road-variant" className="icon-small" />
                        <div>
                            <span>{operation.roadsObj?.[entity.location?.road]?.label ?? ''}</span>
                        </div>
                    </div>
                )}
                <div>
                    <Icon icon="mdi:arrow-collapse-vertical" className="icon-small" />
                    <div>{entity.thickness.toFixed(2) + 'cm'}</div>
                </div>
            </div>
        </div>
    );
}

export const IdentityCardMarker = ({
    entity,
    onClick
}: IdentityCardProps<Marker>) => {
    const { operation } = useWorkspace();
    return (
        <div className="identity-card">
            <div className="identity-card-header">
                <div className="identity-card-name">
                    <Icon icon={MarkerIcon} />
                    {entity.problematic && <Icon icon="fluent:warning-16-regular" className="color-error" />}
                    <span>{entity.name}</span>
                </div>
                <div className="identity-card-tags">
                    <Tag value={entity.phase} items={Phases} />
                </div>
            </div>
            <div className="identity-card-details">
                <div>
                    <Icon icon="mdi:calendar" className="icon-small" />
                    <span>{formatDate(entity.date)}</span>
                </div>
                <div>
                    <Icon icon="mdi:location" className="icon-small" />
                    <div>
                        {operation.synoptique === 1 && !!entity.location?.pr && <span>{floatToPrString(entity.location?.pr) ?? ''}</span>}
                        {!!entity.location?.roadPosition && <span>{entity.location?.roadPosition ? RoadPositionLabel[entity.location?.roadPosition] : ''}</span>}
                        {!!entity.location?.location && <span>{entity.location?.location}</span>}
                    </div>
                </div>
                {!!entity.location.road && (
                    <div>
                        <Icon icon="mdi:road-variant" className="icon-small" />
                        <div>
                            <span>{operation.roadsObj?.[entity.location?.road]?.label ?? ''}</span>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export const IdentityCardPopulation = ({
    entity,
    onClick
}: IdentityCardProps<Population>) => (
    <div className={`identity-card ${entity?.controle ?? ''}`}>
        <div className="identity-card-header">
            <div className="identity-card-name">
                <Icon icon={PopulationIcon} />
                <div>
                    <span>{PopulationTypeLabel[entity.type] ?? ''}</span>
                </div>
            </div>
            <div className="identity-card-tags">
                <Tag value={entity.type} items={PopulationTypes} />
            </div>
        </div>
        <div className="identity-card-details">
            <div><Icon icon={AnalysisIcon} /><div>{formatNumberToFixedDecimal(entity.problematicDataCount)}</div></div>
            {entity?.fullLot && <div><Icon icon={LotIcon.LAYER} /><div>Lot {entity.fullLot}</div></div>}
        </div>
    </div>
)

export const IdentityCardOperation = ({
    entity,
    menu,
    actions
}: IdentityCardProps<Operation>) => (
    <div className="identity-card">
        <div className="identity-card-header">
            <div className="identity-card-name">
                <Icon icon={OperationSubType[entity.type].icon} />
                <div>
                    <span>{entity.name}</span>
                    {menu}
                </div>
            </div>
            <div className="identity-card-tags">
                <Tag value={entity.type} items={OperationSubTypes} />
                <Tag value={entity.phase} items={Phases} />
            </div>
        </div>
        <div className="identity-card-details">
            {!!entity.referential && (
                <div>
                    <Icon icon="mdi:road-variant" className="icon-small" />
                    <div>{entity.referential.substring(0, 1) + entity.referential.substring(1).replace(/^0+/, '')}</div>
                </div>
            )}
            {!!entity.way1 && (
                <div>
                    <Icon icon="mdi:flag" className="icon-small" />
                    <div>{floatToPrString(entity.way1.prStart)} à {floatToPrString(entity.way1.prEnd)}</div>
                </div>
            )}
            <div>
                <Icon icon="mdi:calendar" className="icon-small" />
                <span>{formatDate(entity.date)}</span>
            </div>
            {!!entity.project && <div><Icon icon="mdi:subscriber-identification-module-outline" /><div>{entity.project}</div></div>}
        </div>
        {!!actions && <div className="identity-card-actions">{actions}</div>}
    </div>
);

export const IdentityCardOuvrage = ({
    entity,
    menu,
    actions
}: IdentityCardProps<Ouvrage>) => (
    <div className="identity-card">
        <div className="identity-card-header">
            <div className="identity-card-name">
                <Icon icon={OuvrageIcon[entity.type]} />
                <div>
                    <span>{entity.name}</span>
                    {menu}
                </div>
            </div>
            <div className="identity-card-tags">
                <Tag value={entity.type} items={OuvrageTypes} />
                {entity.diagnostic?.grade && <Tag value={entity.diagnostic.grade} items={OuvrageGrades} />}
            </div>
        </div>
        <div className="identity-card-details">
            <div>
                <Icon icon="mdi:location" className="icon-small" />
                <div>{[entity.location.address?.city, entity.location?.address?.country].filter(v => !!v).join(', ')}</div>
            </div>
            {!!entity.location.network?.referential && (
                <div>
                    <Icon icon="mdi:road-variant" className="icon-small" />
                    <div>{entity.location.network.referential.substring(0, 1) + entity.location.network.referential.substring(1).replace(/^0+/, '')}</div>
                </div>
            )}
            {!!entity.diagnostic?.last && (
                <div>
                    <Icon icon="mdi:calendar" className="icon-small" />
                    <span>Dernier diagnostic: {formatDate(entity.diagnostic.last)}</span>
                </div>
            )}
        </div>
        {!!actions && <div className="identity-card-actions">{actions}</div>}
    </div>
);

const getField = (entity: any, field: string, labels: { [k: string]: string } = {}) => {
    const value = getNestedField(entity, field) ?? '';
    return labels[value] ?? value;
}

export const IdentityCardMaterial = ({
    entity,
    onClick
}: IdentityCardProps<Material>) => (
    <div className="identity-card">
        <div className="identity-card-header">
            <div className="identity-card-name">
                <Icon icon={MaterialIcon} />
                <div>
                    <span>{entity.name}</span>
                </div>
            </div>
            <div className="identity-card-tags">
                <Tag value={entity.type} items={MaterialTypes} />
            </div>
        </div>
        <div className="identity-card-details">
            {(MATERIAL_IDENTITY_FIELDS[entity.type] ?? []).map((item) => (
                <div key={item.field}>
                    <span>{!entity.isStandard && item.labelRequirement ? item.labelRequirement : item.label}</span>
                    <span>{getField(entity, item.field, item.enum)}</span>
                </div>
            ))}
        </div>
    </div>
)

export const IdentityCardUser = ({
    entity,
    onClick
}: IdentityCardProps<User>) => (
    <div className="identity-card">
        <div className="identity-card-header">
            <div className="identity-card-name">
                <Icon icon={UserIcon} />
                <div>
                    <span>{entity.fullName}</span>
                </div>
            </div>
            <div className="identity-card-tags">
                <Tag value={entity.role} items={Roles} />
            </div>
        </div>
        <div className="identity-card-details">
            {!!entity.businessPopulated && (
                <div>
                    <Icon icon={BusinessIcon} className="icon-small" />
                    <span>{entity.businessPopulated.name}</span>
                </div>
            )}
        </div>
    </div>
);

export const IdentityCardLicense = ({
    entity,
    onClick
}: IdentityCardProps<License>) => (
    <div className="identity-card">
        <div className="identity-card-header">
            <div className="identity-card-name">
                <Icon icon={LicenseIcon} />
                <div>
                    <span>{entity.reference}</span>
                </div>
            </div>
        </div>
        <div className="identity-card-details">
            {!!entity?.managerPopulated && (
                <div>
                    <Icon icon={UserIcon} />
                    <div>{getFullName(entity?.managerPopulated)}</div>
                </div>
            )}
            <div>
                <Icon icon="mmdi:calendar" />
                <div><span>{entity.start ? formatDate(entity.start) : ''} à {entity.end ? formatDate(entity.end) : ''}</span></div>
            </div>
        </div>
    </div>
);

export default IdentityCard;