import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import LotModal from "../../../components/ModalSearchEntity/ModalSearchLot";
import DocumentsPage from "../../../components/entities/DocumentsPage";
import EntityCard from "../../../components/entities/EntityCard";
import { IdentityCardLot } from "../../../components/entities/IdentityCard";
import RevisionCard from "../../../components/entities/RevisionCard";
import DatePickerInput from "../../../components/inputs/DatePicker";
import NumberInput from "../../../components/inputs/NumberInput";
import Select from "../../../components/inputs/Select";
import TextInput from "../../../components/inputs/TextInput";
import Button from "../../../components/ui/Button";
import Card from "../../../components/ui/Card";
import Header from "../../../components/ui/Header";
import Menu from "../../../components/ui/Menu";
import MenuBar from "../../../components/ui/MenuBar";
import ScrollableContent from "../../../components/ui/ScrollableContent";
import useForm, { FormComparator } from "../../../hooks/useForm";
import { Lot } from "../../../models/lot";
import { Population, PopulationData, PopulationType, PopulationTypeLabel, PopulationTypes } from "../../../models/population";
import { Controle, ControleLabel } from "../../../models/sample";
import { RevisionStatus } from "../../../models/shared";
import useWorkspace from "../../../services/hooks/use-workspace";
import { getRequest, postRequest, putRequest } from "../../../services/request.service";
import { formatNumberToFixedDecimal } from "../../../utils/format";
import { ActionIcon } from "../../../utils/icons";
import { average } from "../../../utils/maths";
import PopulationRoadDataSheet from "./PopulationRoadDataSheet";
import PopulationZoneDataSheet from "./PopulationZoneDataSheet";
import './index.scss';

const VALIDATION = {
    type: [{ comparator: FormComparator.REQUIRED }],
};

const PopulationEdit = () => {
    const { operation, workspacePermissions } = useWorkspace();
    const [isLotModalVisible, setLotModalVisible] = useState<boolean>(false);
    const { page, populationId, lotId } = useParams();
    const navigate = useNavigate();
    const [mode, setMode] = useState<'data' | 'files'>('data');
    const [controleToEdit, setControleToEdit] = useState<Controle>();
    const { entity, attachInput, validate, setEntity, onChange, setChanged, onChangeMultiple, hasChanged } = useForm<Population>({});

    const isTypeDisabled = useMemo(() => !!entity._id || entity.controle?.autre?.data?.length || entity.controle?.exterieur?.data?.length || entity.controle?.externe?.data?.length, [entity]);

    const handleLotChange = useCallback((lot: Lot) => {
        onChangeMultiple([
            { field: 'lot', value: lot._id },
            { field: 'lotPopulated', value: lot },
            { field: 'fullLot', value: lot.fullLot },
        ])
        setLotModalVisible(false);
    }, [onChangeMultiple]);

    const handleSubmit = useCallback(async (close?: boolean) => {
        const entity = validate(VALIDATION);
        if (!entity) return;

        const dto = {
            ...entity,
            material: entity.material ?? entity.lotPopulated?.material,
            lot: entity.lotPopulated?._id,
        };
        const create = !entity._id;
        const requestMethod = create ? postRequest : putRequest;

        requestMethod<Population>('/population', dto, {
            successMessage: create ? 'Population mise à jour avec succès' : 'Population créée avec succès',
            errorMessage: 'Une erreur est survenue lors de l\'enregistrement',
            loader: true
        })
            .then((data) => {
                if (close) {
                    navigate(-1)
                } else {
                    setChanged(false);
                    setEntity(data);
                }
            })
            .catch(() => null);
    }, [validate, entity, setEntity]);

    const handleDataChange = useCallback((data: PopulationData[], controle: Controle) => {
        const values = data.map(d => d.value);
        onChangeMultiple([
            { field: `controle.${controle}.data`, value: data },
            {
                field: `controle.${controle}.statistics`, value: {
                    min: Math.min(...values),
                    max: Math.max(...values),
                    average: formatNumberToFixedDecimal(average(values)),
                }
            },
        ]);
    }, [onChangeMultiple])

    useEffect(() => {
        const population = {
            type: PopulationType.PMT,
            operation: operation._id,
            controle: {},
            active: true,
            status: workspacePermissions.validate ? RevisionStatus.VALIDATED : RevisionStatus.DRAFT,
        }
        if (populationId) {
            getRequest<Population>(`/population/${populationId}${page === 'dupliquer' ? '/duplicate' : ''}`, { errorMessage: 'Une erreur est survenue lors de la récupération de la entity.', loader: true })
                .then(setEntity)
                .catch(() => navigate(-1));
        } else if (lotId) {
            getRequest<Lot>(`/lot/${lotId}`, { loader: true })
                .then((lot) => setEntity({ ...population, lot: lot._id, lotPopulated: lot, fullLot: lot.fullLot }))
                .catch(() => setEntity({ ...population }));
        } else {
            setEntity({ ...population });
        }
    }, []);

    return (
        <Fragment>
            <Header
                breadcrumbs={[
                    { label: operation?.name, href: `/operation/${operation?._id}` },
                    { label: 'Travaux', href: `/operation/${operation?._id}/travaux/population` },
                    ...(entity._id && entity.lotPopulated
                        ? [{ label: 'Lot ' + entity.lotPopulated.fullLot, href: `/operation/${operation?._id}/travaux/lot/${entity.lotPopulated._id}/detail/population` }]
                        : []
                    ),
                    { label: entity._id ? PopulationTypeLabel[entity.type as PopulationType] : 'Nouvelle population' }
                ]}
                withBorder
            >
                <Button
                    label="Fermer"
                    color="secondary"
                    onClick={() => navigate(-1)}
                />
                {hasChanged && (
                    <Fragment>
                        <Button
                            label="Enregistrer"
                            icon={ActionIcon.SAVE}
                            onClick={handleSubmit}
                        />
                        <Button

                            label="Enregistrer et fermer"
                            icon={ActionIcon.SAVE_CLOSE}
                            onClick={() => handleSubmit(true)}
                        />
                    </Fragment>
                )}
            </Header>
            <MenuBar>
                <MenuBar.Item
                    label="Informations"
                    onClick={() => setMode('data')}
                    active={mode === 'data'}
                    icon="mdi:form-outline"
                />
                <MenuBar.Item
                    label="Fichiers"
                    onClick={() => setMode('files')}
                    active={mode === 'files'}
                    icon="mdi:files"
                />
            </MenuBar>
            <ScrollableContent
                id="population-edit"
                side={
                    <EntityCard entity={entity} attachInput={attachInput}>
                        <RevisionCard<Population> entity={entity} attachInput={attachInput} />
                    </EntityCard>
                }
            >
                {mode === 'data' && (
                    <Fragment>
                        <Card title="Lot" actions={!lotId ? <Menu icon={ActionIcon.SEARCH} label="Sélectionner" onClick={() => setLotModalVisible(true)} /> : undefined}>
                            {!!entity.lotPopulated && (
                                <IdentityCardLot entity={entity.lotPopulated} />
                            )}
                        </Card>
                        <Card title="Informations générales">
                            <div className="input-column">
                                <label htmlFor="type">Type de mesure</label>
                                <Select disabled={isTypeDisabled} items={PopulationTypes} direction="row" {...attachInput('type')} />
                            </div>
                            {entity.type === PopulationType.VIDE && (
                                <div className="input-column">
                                    <label htmlFor="depth">Profondeur de mesure</label>
                                    <TextInput type="number" {...attachInput('depth')} />
                                </div>
                            )}
                        </Card>
                        <Card title="Analyses">
                            {!entity.lotPopulated ? (
                                <div className="warn">Pour accéder à la saisie des données, veuillez sélectionner un lot</div>
                            ) : (
                                <Fragment>
                                    <div id="population-controles">
                                        <table className="simple-table simple-table-label-left">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    {workspacePermissions.controles?.map(c => <th key={c}>{ControleLabel[c]}</th>)}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Laboratoire</td>
                                                    {workspacePermissions.controles?.map(c => (<td key={c}><TextInput {...attachInput(`controle.${c}.laboratory`)} /></td>))}
                                                </tr>
                                                <tr>
                                                    <td>Date d'analyse</td>
                                                    {workspacePermissions.controles?.map(c => (<td key={c}><DatePickerInput max={new Date()} withIcon {...attachInput(`controle.${c}.date`)} /></td>))}
                                                </tr>
                                                <tr>
                                                    <td>Minimum</td>
                                                    {workspacePermissions.controles?.map(c => (<td key={c}><NumberInput disabled={!!entity?.controle?.[c]?.data?.length} {...attachInput(`controle.${c}.statistics.min`)} /></td>))}
                                                </tr>
                                                <tr>
                                                    <td>Moyenne</td>
                                                    {workspacePermissions.controles?.map(c => (<td key={c}><NumberInput disabled={!!entity?.controle?.[c]?.data?.length} {...attachInput(`controle.${c}.statistics.average`)} /></td>))}
                                                </tr>
                                                <tr>
                                                    <td>Maximum</td>
                                                    {workspacePermissions.controles?.map(c => (<td key={c}><NumberInput disabled={!!entity?.controle?.[c]?.data?.length} {...attachInput(`controle.${c}.statistics.max`)} /></td>))}
                                                </tr>
                                                <tr>
                                                    <td>Données renseignées</td>
                                                    {workspacePermissions.controles?.map(c => (<td key={c}><NumberInput id="dataCount" disabled={true} value={entity?.controle?.[c]?.data?.length} onChange={(v) => null} /></td>))}
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    {workspacePermissions.controles?.map(c => (
                                                        <td key={c}>
                                                            <Button label="Editer les données" icon={ActionIcon.EDIT} onClick={() => setControleToEdit(c)} />
                                                        </td>
                                                    ))}
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <p className="info">Les statistiques sont calculées automatiquement si la saisie de données brutes est activée</p>
                                </Fragment>
                            )}
                        </Card>
                    </Fragment>
                )}
                {!!controleToEdit && operation.synoptique === 1 && (
                    <PopulationRoadDataSheet
                        population={entity}
                        data={entity.controle?.[controleToEdit]?.data}
                        onSubmit={(data) => handleDataChange(data, controleToEdit)}
                        onClose={() => setControleToEdit(undefined)}
                    />
                )}
                {!!controleToEdit && operation.synoptique !== 1 && (
                    <PopulationZoneDataSheet
                        population={entity}
                        data={entity.controle?.[controleToEdit]?.data}
                        onSubmit={(data) => handleDataChange(data, controleToEdit)}
                        onClose={() => setControleToEdit(undefined)}
                    />
                )}
                {mode === 'files' && <DocumentsPage entityId={entity._id} url={entity._id ? `/population/${entity._id}/files` : undefined} onChange={(type, files) => onChange(type, files)} />}
            </ScrollableContent>
            {isLotModalVisible && (
                <LotModal
                    onSubmit={handleLotChange}
                    onClose={() => setLotModalVisible(false)}
                />
            )}
        </Fragment >
    )
}

export default PopulationEdit;